import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import * as React from 'react';

export const query = graphql`
  query {
    file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fixed(width: 125, height: 125) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const links = [
  {
    active: true,
    title: 'Sails',
    description:
      'Lav lynhurtigt nye tilbud, modtag ordrer og send faktura til dine kunder. Sails snakker sammen med dit økonomisystem og automatiserer bogføringen.',
    link: 'https://get-sails.dk',
  },
  {
    active: true,
    title: 'Turnavisual',
    description:
      'En 3D-emballagedesign platform der hjælper emballage producenter, brand owners og designere til at reducere time to market og levere produkter af høj kvalitet.',
    link: 'https://tav3d.com',
  },
  {
    active: true,
    title: 'The Pet Portrait',
    description:
      'Har du kæledyr, så ved du, at de har en helt særlig plads i hjertet. Nu kan de også få deres egen plads på væggen med et personligt og stilfuldt billede.',
    link: 'https://thepetportrait.dk',
  },
  {
    active: false,
    title: 'Studio Shapes',
    description:
      "Bestil og opbevar 3D modeller af dine emballager og modtag din 3D-model til brug i Esko's grafiske software indenfor et par arbejdsdage.",
  },
  {
    active: false,
    title: 'Flow',
    description:
      'Flow forbinder dine processer fra ordremodtagelse i webshop til produktion af varer. Flow gør det let at planlægge og automatisere alle produktionsopgaver.',
  },
];

const IndexPage = ({ data }) => {
  return (
    <main className="text-gray-900">
      <div className="max-w-3xl w-full mx-auto pt-16 px-4">
        <Img className="mx-auto" fixed={data.file.childImageSharp.fixed} />
        <div className="my-6 text-4xl font-semibold">Nexagon</div>

        <p className="my-2">
          Nexagon er et software udviklingshus der i en årrække har udviklet
          flere forskellige softwareløsninger, primært til den grafiske
          industri. Vi leverer ikke længere konsulentydelser men vedligeholder
          dog stadig vores egne løsninger, som du kan finde herunder. Ved
          spørgsmål bedes du henvende dig til{' '}
          <a
            className="underline text-gray-700"
            href="mailto:magnus@nexagon.dk"
          >
            magnus@nexagon.dk
          </a>
          .
        </p>
        <p className="my-2">Rigtig god dag!</p>
      </div>

      <div className="text-grey-700 max-w-3xl w-full mx-auto pt-8 px-4">
        <div className="mt-6 mb-4 text-2xl font-semibold">Vores løsninger</div>

        {links.map((link, i) => (
          <div key={i} className="py-4">
            <p className="text-lg font-semibold">
              {link.active ? '🚀' : '☠️'} {link.title}
            </p>
            <p className="mb-1">{link.description}</p>
            {link.link ? (
              <a className="underline text-gray-700" href={link.link}>
                Gå til {link.title}
              </a>
            ) : (
              <p className="text-gray-500">Udgået produkt</p>
            )}
          </div>
        ))}
      </div>

      <div className="text-grey-700 max-w-3xl w-full mx-auto py-16 px-4 mt-16 border-t border-gray-200">
        <p className="text-center text-sm text-gray-500">
          Nexagon IVS
          <br /> CVR DK40081593
        </p>
      </div>
    </main>
  );
};

export default IndexPage;
